import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { Layout, Container } from 'layouts';
import { Header, CardList, SEO } from 'components';
import config from '../../config/site';
import i18n from '../i18n/i18n';

const Category = ({
  data,
  pageContext: {
    categoryName,
    locale,
    defaultSlug,
    supportedLangs,
  }
}) => {

  i18n.changeLanguage(locale)

  /** 
   * カテゴリの記事が0件の場合でも
   * gatsby-node.jsで全カテゴリのページを生成するので
   * 存在チェックをする
   */
  const Content = data.allMarkdownRemark && data.allMarkdownRemark.edges.length > 0 
    ? <CardList list={data.allMarkdownRemark.edges} />
    : <div>No Article</div>

  const fitstUpperCategory = categoryName.charAt(0).toUpperCase() + categoryName.slice(1);
  const upperCategory = categoryName.toUpperCase();

  return (
    <Layout
      locale={locale}
      defaultSlug={defaultSlug}
      supportedLangs={supportedLangs}
    >
      <SEO
        title={`${fitstUpperCategory} - ${config[locale].title}`}
        pathname={`/${categoryName}`}
        article
      />
      <Header title={upperCategory} subTitle={i18n.t("categoryPage.subtitle")} />
      <Container type="big">
        {Content}
      </Container>
    </Layout>
  );
};

export default Category;

Category.propTypes = {
  pageContext: PropTypes.shape({
    posts: PropTypes.array,
    categoryName: PropTypes.string,
  }),
};


/**
 * ロケールでフィルターする
 */
export const query = graphql`
  query($categoryName: String!, $locale: String!) {
    allMarkdownRemark(
      filter: {
        frontmatter: { categories: { eq: $categoryName } }
        fields: { localePath: { eq: $locale } }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 75)
          fields {
            localePath
            slug
            defaultSlug
          }
          frontmatter {
            title
            path
            tags
            date
            cover {
              childImageSharp {
                fluid(
                  maxWidth: 640
                  maxHeight: 384
                  quality: 50
                  traceSVG: { color: "#B6BCCF" }
                ) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`;
